// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import {navigate} from "gatsby";
import * as queryString from "query-string";

import Signedlayout, {accountTab} from "../components/Signedlayout";
import SelectProductCard from "../components/SelectProductCard";
import Product from "../components/Product";
import {getStringPriceFromCents, skeletonProducts} from "./my-products";
import {reduce} from "ramda";
import CreateProjectModal from "../components/CreateProjectModal";
import DeleteProjectModal from "../components/DeleteProjectModal";
import UpdateProjectModal from "../components/UpdateProjectModal";
import {getFirebase} from "../utils/signin";
import Layout from "../components/layout";

function LegalNotice({}) {

    return (
        <Layout>
            <div className="pt-24 pb-1">
                <div className="mx-auto">
                    <div className="w-full mx-auto">
                        <div>
                            <div className='py-14 text-center text-3xl'>
                                <h1>Conditions générales d'utilisation</h1>
                            </div>
                            <br/>
                            <div className='bg-white text-gray-600'>
                                <div className='container w-full mx-auto py-10'>
                                    <h1 align="center">
                                        <em>Mis à jour le : 17 août 2021</em>
                                    </h1>
                                    <p>
                                        <strong><u>PREAMBULE</u></strong>
                                    </p>
                                    <p>
                                        Deerhome est une plateforme web, disponible à l’adresse
                                        https://deerhome.fr/ (le ‘<strong>Site</strong>’).
                                    </p>
                                    <p>
                                        Ce Site est édité par Raidouane EL MOUKHTARI, entrepreneur individuel
                                        domicilié au 108 Cours Saint-Louis, 33300 Bordeaux, France. Cet
                                        entrepreneur est immatriculé au registre du commerce et des sociétés de
                                        Bordeaux sous le numéro d’identification 83033419900015, (ci-après ‘    <strong>l’Editeur</strong>’) . Deerhome est accessibles aux utilisateurs
                                        (le ou les ‘<strong>Utilisateurs</strong>’).
                                    </p>
                                    <p>
                                        DeerHome est hébergé par Netlify, Société aux USA, dont le siège social est
                                        situé au 2343 3rd Street, 94107 San Francisco, États-Unis. Cet hébergeur
                                        peut être contacté à cette adresse électronique : N/A.
                                    </p>
                                    <p>
                                        L’objet des présentes Conditions Générales d’Utilisation (les ‘<strong>Conditions’</strong> ou prises dans leur ensemble, le ‘    <strong>Contrat</strong>’) est de définir les termes et les conditions
                                        régissant les relations entre les Utilisateurs et Raidouane EL MOUKHTARI .
                                        En cas de non-respect des termes des présentes Conditions, Raidouane EL
                                        MOUKHTARI se réserve le droit de prendre toute mesure de nature à préserver
                                        ses intérêts et notamment à en assurer l'exécution.
                                    </p>
                                    <p>
                                        L’Utilisateur s’engage lors de chacune de ses visites sur la Plateforme à
                                        respecter l’ensemble des présentes Conditions sans aucune réserve. En
                                        conséquence, l’Utilisateur reconnait avoir pris connaissance des Conditions
                                        et accepte d’être lié par les présentes dispositions. Si l’Utilisateur
                                        accède à la Plateforme pour le compte d’une entreprise ou de toute autre
                                        entité juridique, il est néanmoins personnellement lié par le présent
                                        Contrat.
                                    </p>
                                    <br clear="all"/>
                                    <p>
                                        <strong><u>ARTICLE 1<sup>ER</sup> : OBJET DU SITE</u></strong>
                                    </p>
                                    <p>
                                        Le Site permet à l’Utilisateur d’accéder notamment aux services suivants :
                                        Services et Productivité destiné aux décorateurs ou similaires (le ou les ‘    <strong>Service(s)</strong>’) .
                                    </p>
                                    <p>
                                        Raidouane EL MOUKHTARI , selon les présentes Conditions, accorde aux
                                        Utilisateurs un droit d’accès limité révocable, non exclusif, non cessible
                                        aux Services à titre strictement personnel. Toute utilisation de la
                                        Plateforme contraire à sa finalité est strictement interdite et constitue
                                        un manquement aux présentes dispositions.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 2 : ACCES AU SITE</u></strong>
                                    </p>
                                    <p>
                                        Pour être éligible au Service, l’Utilisateur peut être une personne
                                        physique ou morale.
                                    </p>
                                    <p>
                                        Raidouane EL MOUKHTARI se réserve le droit de suspendre ou de refuser un
                                        accès d’un ou plusieurs Utilisateurs au Site.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 3 : GESTION DU SITE</u></strong>
                                    </p>
                                    <p>
                                        L’Editeur met en œuvre des solutions techniques nécessaires afin de
                                        permettre un accès continu au site pour l’Utilisateur. Toutefois il se
                                        réserve la possibilité de limiter ou suspendre l’accès à tout ou partie du
                                        Site à tout moment, et sans préavis. Une telle interruption pourra
                                        notamment être rendue nécessaire pour des raisons de maintenances du site
                                        ou de son contenu, ou pour toute autre raison jugée nécessaire au bon
                                        fonctionnement du site par l’Editeur.
                                    </p>
                                    <p>
                                        L’utilisation de la Plateforme requiert une connexion et un navigateur
                                        internet. La plateforme est accessible à l’adresse suivante :
                                        https://deerhome.fr/. Afin de garantir un bon fonctionnement du Site, il
                                        est précisé que le Site est optimisé pour :
                                    </p>
                                    <p>
                                        - une résolution d’écran de 1280×768 pixels ;
                                    </p>
                                    <p>
                                        - les dernières versions des navigateurs Edge, Chrome, Firefox et Safari.
                                    </p>
                                    <p>
                                        Tous matériels et logiciels nécessaires à l’accès à la Plateforme et à
                                        l’utilisation des Services restent exclusivement à la charge de
                                        l’Utilisateur.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 4 : SIGNALEMENT</u></strong>
                                    </p>
                                    <p>
                                        L’utilisateur a la possibilité de contacter l’Editeur afin de lui signaler
                                        tout comportement ou contenu illicite ou manifestement inapproprié. Un tel
                                        signalement pourra être fait par mail à l’adresse dev.deerhome@gmail.com
                                        <strong>
                                            <u>ARTICLE 5 : UTILISATION DES SERVICES DE LA PLATEFORME</u>
                                        </strong>
                                    </p>
                                    <p>
                                        <strong><u>1.1 Obligations des utilisateurs</u></strong>
                                    </p>
                                    <p>
                                        Les Utilisateurs s’interdisent :
                                    </p>
                                    <p>
                                        1. de transmettre, publier, distribuer, enregistrer ou détruire tout
                                        matériel, en particulier les contenus de DeerHome, en violation des lois ou
                                        règlementations en vigueur concernant la collecte, le traitement ou le
                                        transfert d'informations personnelles ;
                                    </p>
                                    <p>
                                        2. de diffuser des données, informations, ou contenus à caractère
                                        diffamatoire, injurieux, obscène, offensant, violent ou incitant à la
                                        violence, ou à caractère politique, raciste ou xénophobe et de manière
                                        générale tout contenu qui serait contraire aux lois et règlements en
                                        vigueur ou aux bonnes mœurs ;
                                    </p>
                                    <p>
                                        3. de référencer ou créer des liens vers tout contenu ou information
                                        disponible depuis les sites de DeerHome, sauf accord exprès, préalable et
                                        écrit de Raidouane EL MOUKHTARI ;
                                    </p>
                                    <p>
                                        4. d’utiliser des informations, contenus ou toutes données présentes sur le
                                        Site afin de proposer un service considéré comme concurrentiel à DeerHome.
                                    </p>
                                    <p>
                                        5. de vendre, échanger ou monnayer des informations, contenus ou données
                                        présentes sur la plateforme ou des Service proposés par la Plateforme, sans
                                        l’accord expresse et écrit de Raidouane EL MOUKHTARI ;
                                    </p>
                                    <p>
                                        6. de pratiquer de l’ingénierie inversée (Reverse Engineering), décompiler,
                                        désassembler, déchiffrer ou autrement tenter d’obtenir le code source en
                                        relation avec toute propriété intellectuelle sous-jacente utilisée pour
                                        fournir tout ou partie des Services ;
                                    </p>
                                    <p>
                                        7. d’utiliser des logiciels ou appareils manuels ou automates, robots de
                                        codage ou autres moyens pour accéder, explorer, extraire ou indexer toute
                                        page du Site ;
                                    </p>
                                    <p>
                                        8. de mettre en danger ou essayer de mettre en danger la sécurité numérique
                                        de DeerHome. Cela comprend les tentatives de contrôler, scanner ou tester
                                        la vulnérabilité du système ou réseau ou de violer des mesures de sécurité
                                        ou d’authentification sans une autorisation préalable expresse ;
                                    </p>
                                    <p>
                                        9. de contrefaire ou d’utiliser les produits, les logos, les marques ou
                                        tout autre élément protégé par les droits de propriété intellectuel de
                                        Raidouane EL MOUKHTARI ;
                                    </p>
                                    <p>
                                        10. de simuler l’apparence ou le fonctionnement du Site, en procédant par
                                        exemple à un effet miroir ;
                                    </p>
                                    <p>
                                        11. de perturber ou troubler, directement ou indirectement DeerHome, ou
                                        imposer une charge disproportionnée sur l’infrastructure du Site, ou de
                                        tenter de transmettre ou d’activer des virus informatique via ou sur le
                                        Site.
                                    </p>
                                    <p>
                                        Il est rappelé que les violations de la sécurité du système ou du réseau
                                        peuvent conduire à des poursuites civiles et pénales. Raidouane EL
                                        MOUKHTARI vérifie l’absence de telle violation et peut faire appel aux
                                        autorités judiciaires pour poursuivre, le cas échéant, des Utilisateurs
                                        ayant participé à de telles violations.
                                    </p>
                                    <p>
                                        Les Utilisateurs s’engagent à utiliser le Site de manière loyale,
                                        conformément à sa finalité et aux dispositions légales, règlementaires, aux
                                        présentes Conditions et aux usages en vigueur.
                                    </p>
                                    <p>
                                        <strong><u>1.2 Compte clients des utilisateurs</u></strong>
                                    </p>
                                    <p>
                                        L’Editeur réserve certains services de DeerHome aux utilisateurs ayant
                                        procédé à leur inscription sur le site, cela concerne notamment les
                                        services payants. Lors de son inscription, l’utilisateur s’engage à fournir
                                        des informations valables et sincères. Il s’engage notamment à fournir à
                                        l’Editeur une adresse mail afin que ce dernier puisse lui communiquer des
                                        informations.
                                    </p>
                                    <p>
                                        Toute information communiquée par mail de l’Editeur à l’Utilisateur sera
                                        réputée avoir été lue par ce dernier.
                                    </p>
                                    <p>
                                        Tout utilisateur régulièrement inscrit sur le Site pourra demander la
                                        suppression de son compte client, l’Editeur s’engage à supprimer les
                                        comptes clients pour lesquels il aura reçu une telle demande.
                                    </p>
                                    <p>
                                        Les données personnelles des clients sont supprimées au bout de 24 mois
                                        sans que le client ne se soit connecté.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 6 : PROPRIETE INTELECTUELLE</u></strong>
                                    </p>
                                    <p>
                                        L’ensemble du contenu du Site, notamment les designs, textes, graphiques,
                                        images, vidéos, informations, logos, icônes-boutons, logiciels, fichiers
                                        audio et autres appartient à Raidouane EL MOUKHTARI , lequel est seul
                                        titulaire de l’intégralité des droits de propriété intellectuelle y
                                        afférents.
                                    </p>
                                    <p>
                                        Toute représentation et/ou reproduction et/ou exploitation partielle ou
                                        totale des contenus et Services proposés par DeerHome, par quelque procédé
                                        que ce soit, sans l'autorisation préalable et écrite de Raidouane EL
                                        MOUKHTARI , est strictement interdite et serait susceptible de donner lieu
                                        à des poursuites judiciaires.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 7 : DONNEES A CARACTERE PERSONNELLES</u></strong>
                                    </p>
                                    <p>
                                        Toutes les données à caractère personnel dont dispose Raidouane EL
                                        MOUKHTARI sont recueillies légalement et loyalement selon les modalités de
                                        la politique de confidentialité accessible à cette adresse :
                                        https://deerhome.fr/privacy-policy.
                                    </p>
                                    <p>
                                        Ces données sont fournies par les Utilisateurs qui acceptent de manière
                                        volontaire et expresse les présentes Conditions autorisant Raidouane EL
                                        MOUKHTARI à traiter, divulguer ou transférer ces données à tout tiers cela
                                        afin de permettre (i) à l’Utilisateur de profiter pleinement des Services
                                        et des fonctions proposés par le Site, (ii) prévenir toute fraude et /ou
                                        (iii) à des fins statistiques.
                                    </p>
                                    <p>
                                        Afin de faciliter l’utilisation du Site et de personnaliser l’expérience du
                                        Site par l’Utilisateur, Raidouane EL MOUKHTARI utilise des cookies et
                                        fichiers journaux. L’utilisation des cookies peut être désactivée en
                                        modifiant les paramètres du navigateur internet.
                                    </p>
                                    <p>
                                        Les données à caractère personnel sont stockées par Raidouane EL MOUKHTARI
                                        en vue de leur traitement dans le cadre de l’utilisation des Services.
                                        Elles sont conservées aussi longtemps que nécessaire pour l’apport des
                                        Services et fonctions offerts par le Site.
                                    </p>
                                    <p>
                                        L’Utilisateur reste toujours propriétaire des informations le concernant
                                        qu’il transmet à Raidouane EL MOUKHTARI . Il dispose, conformément à la loi
                                        n° 78-17 du 6 janvier 1978 selon sa version consolidée au 24 mars 2020,
                                        d’un droit d’accès, de rectification et de suppression des données à
                                        caractère personnel le concernant, ainsi que du droit de s’opposer à la
                                        communication de ces données à des tiers pour de justes motifs.
                                    </p>
                                    <p>
                                        L’Utilisateur pourra exercer ses droits en écrivant à l’adresse
                                        électronique suivante : dev.deerhome@gmail.com
                                    </p>
                                    <p>
                                        L’Utilisateur pourra également exercer ses droits en écrivant à l’adresse
                                        postale suivante : ***postale TX***.
                                    </p>
                                    <p>
                                        Une réponse à la requête de l’Utilisateur lui sera adressée dans un délai
                                        de 30 jours.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 8 : RESPONSABILITE</u></strong>
                                    </p>
                                    <p>
                                        Il est rappelé que les données publiées par les Utilisateurs et les
                                        informations partagées par ces derniers peuvent être captées et exploitées
                                        par d’autres Utilisateurs ou des tiers. En ce sens, Raidouane EL MOUKHTARI
                                        ne garantit pas le respect de la propriété de ces données, il incombe à
                                        l’Utilisateur de prendre l’ensemble des dispositions nécessaires afin que
                                        soit préservée la propriété de ses données.
                                    </p>
                                    <p>
                                        Raidouane EL MOUKHTARI ne garantit pas le fonctionnement sans interruption
                                        ou sans erreur de fonctionnement des Services, en particulier, la
                                        responsabilité de Raidouane EL MOUKHTARI ne saurait être engagée en cas
                                        d’interruption d’accès à la Plateforme en raison d’opérations de
                                        maintenance, de mises à jour ou d’améliorations techniques.
                                    </p>
                                    <p>
                                        En tout état de cause, Raidouane EL MOUKHTARI ne saurait en aucune
                                        circonstance être responsable au titre des pertes ou dommages indirects ou
                                        imprévisibles de l’Utilisateur ou de tout tiers, ce qui inclut notamment
                                        tout gain manqué, tout investissement malheureux, inexactitude ou
                                        corruption de fichiers ou données, préjudice d’image ou commercial, perte
                                        de chiffre d’affaires ou bénéfice, perte de clientèle ou perte de chance
                                        lié à quelque titre et sur quelque fondement que ce soit.
                                    </p>
                                    <p>
                                        En outre, Raidouane EL MOUKHTARI ne saurait être responsable de tout retard
                                        ou inexécution du présent Contrat justifié par un cas de force majeure,
                                        telle qu’elle est définie par la jurisprudence des cours et tribunaux
                                        français.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 9 : CONVENTION DE PREUVES</u></strong>
                                    </p>
                                    <p>
                                        Les systèmes et fichiers informatiques font foi dans les rapports entre
                                        Raidouane EL MOUKHTARI et l’Utilisateur.
                                    </p>
                                    <p>
                                        Ainsi, Raidouane EL MOUKHTARI pourra valablement produire dans le cadre de
                                        toute procédure, aux fins de preuve les données, fichiers, programmes,
                                        enregistrements ou autres éléments, reçus, émis ou conservés au moyen des
                                        systèmes informatiques exploités, sur tous supports numériques ou
                                        analogiques, et s’en prévaloir sauf erreur manifeste.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 10 : INDIVISIBILITE</u></strong>
                                    </p>
                                    <p>
                                        Le fait que l’une quelconque des dispositions du Contrat soit ou devienne
                                        illégale ou inapplicable n’affectera en aucune façon la validité ou
                                        l’applicabilité des autres stipulations du Contrat.
                                    </p>
                                    <p>
                                        <strong><u>ARTICLE 11 : REGLEMENT DES DIFFERENTS</u></strong>
                                    </p>
                                    <p>
                                        La conclusion, l’interprétation et la validité du présent Contrat sont
                                        régis par la loi française, quelque soit le pays d’origine de l’Utilisateur
                                        ou le pays depuis lequel l’Utilisateur accède à DeerHome et nonobstant les
                                        principes de conflits de lois.
                                    </p>
                                    <p>
                                        Dans l’hypothèse où un différend portant sur la validité, l’exécution ou
                                        l’interprétation du présent Contrat et serait porté devant les juridictions
                                        civiles, il sera soumis à la compétence exclusive des tribunaux français
                                        auquel il est fait expressément attribution de compétence, même en cas de
                                        référé ou de pluralité de défendeurs.
                                    </p>
                                    <p>
                                        L’Utilisateur est informé qu’il peut en tout état de cause recourir à une
                                        médiation conventionnelle ou à tout mode alternatif de règlement des
                                        différends (conciliation par exemple) en cas de contestation.    <strong></strong>
                                    </p>
                                    <p>
                                        <strong>
                                            <u>ARTICLE 12 : DUREE DES CONDITIONS GENERALES D’UTILISATIONS</u>
                                        </strong>
                                    </p>
                                    <p>
                                        Les présentes conditions générales d’utilisation sont conclues pour une
                                        durée indéterminée, l’Utilisateur est tenu de les respecter à compter du
                                        début de son utilisation des Services.
                                    </p>
                                    <p>
                                        L’Editeur se réserve la possibilité de modifier ce document à tout moment
                                        et sans préavis. Les utilisateurs seront informés de chaque mise à jour du
                                        document.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default LegalNotice;